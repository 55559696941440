import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ThemeToggle } from "@/components/layout/ThemeToggle";
import { SiteLogo } from "@/components/SiteLogo";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { login } from "@/features/auth/api/authApi";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string()
    .required(i18n.t("Pole jest wymagane"))
    .test("valid-email", "Adres e-mail jest niepoprawny", (value) => {
      if (value.includes("/")) {
        const emails = value.split("/");
        return emails.every((email) => Yup.string().email().isValidSync(email));
      }
      return Yup.string().email("Pole musi być poprawnym adresem email").isValidSync(value);
    }),
  password: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const LoginPage = () => {
  useDocumentTitle("Logowanie");
  const { logIn, user } = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", password: "" },
    mode: "onBlur",
  });

  const onSubmit = async ({ email, password }) => {
    const response = await login(email, password);
    if (response.ok) {
      logIn(response.data);
      return navigate(routes.dashboard);
    } else {
      if (response.status === 401) {
        toast({ title: <ErrorToast title="Zły login lub hasło" /> });
      } else {
        toast({ title: <ErrorToast title={response.data.error} /> });
      }
    }
  };

  useEffect(() => {
    if (user !== null) return navigate(routes.dashboard, { replace: true });
  }, [user]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <SiteLogo className="mx-auto w-1/4" />
      </div>
      <Card className="w-full max-w-sm mt-8">
        <CardHeader>
          <CardTitle className="text-xl">{i18n.t("Zaloguj się")}</CardTitle>
        </CardHeader>
        <CardContent>
          <Form form={form} onSubmit={onSubmit}>
            <div className="grid gap-4">
              <FormField name="email" label="Email" type="email" />
              <FormField name="password" label="Hasło" type="password" />
              <Button
                className="w-full mt-3"
                isLoading={form.formState.isSubmitting}
                title="Zaloguj się"
                type="submit"
              />
            </div>
          </Form>
        </CardContent>
        <CardFooter className="flex flex-col items-center justify-center">
          <Link
            to={routes.forgotPassword}
            className="text-sm font-semibold text-primary text-center hover:text-primary/80"
          >
            {i18n.t("Nie pamiętam hasła")}
          </Link>
        </CardFooter>
      </Card>
      <ThemeToggle className="mt-3" />
    </div>
  );
};
